import { useEffect, useState } from 'react';

function Post({ id, title, username, content }) {
    return (
        <div key={id}>
            <h2>{title}</h2>
            <small>{username}</small>
            <p>{content}</p>
        </div>
    );
}

export default function Posts() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch('https://worker.goolu.workers.dev/posts', {
            method: 'GET',
        })
            .then((res) => res.json())
            .then(setPosts);
    }, []);

    return (
        <div>
            <h1>Posts</h1>
            {posts.map((post) => (
                <Post {...post} />
            ))}
        </div>
    );
}
